import { ThemeOptions } from '@mui/material';
import UTILS from '../../utils';
import typography from '$styles/typography';
import Colors from '$colors';


const getInPx = (number: number) => `${number}px`;


const textField: ThemeOptions['components'] = {
  MuiTextField: {
    variants: [
      {
        props: { color: 'white' },
        style: {
          '.MuiInputBase-root': {
            borderRadius: UTILS.getInPx(16) + '!important',
          },
          fieldset: {
            border: '1px solid ' + Colors.white + '!important',
            borderRadius: UTILS.getInPx(16),
            background: `${Colors.white} !important`,
            paddingLeft: '16px !important',
          },
          input: {
            zIndex: 10,
            color: `${Colors.black} !important`,
          },
          '&.Mui-disabled': {
            color: Colors.white,
            'input': {
              color: Colors.black,
              WebkitTextFillColor: Colors.black,
            },
          },
        },
      },
      {
        props: { color: 'black' },
        style: {
          '.MuiInputBase-root': {
            borderRadius: UTILS.getInPx(16) + '!important',
          },
          label: {
            color: 'black !important',
          },
          fieldset: {
            border: '1px solid ' + Colors.gray4 + '!important',
            borderRadius: UTILS.getInPx(16),
            background: `${Colors.white} !important`,
            paddingLeft: '16px !important',
          },
          input: {
            zIndex: 10,
            fontWeight: 300,
            height: "32px !important",
            maxHeight: "32px !important",
            whiteSpace: "unset !important",
            fontSize: getInPx(16),
            lineHeight: getInPx(18),
            color: `${Colors.black} !important`,
          },
          '&.Mui-disabled': {
            color: Colors.black,
            'input': {
              color: Colors.black,
              WebkitTextFillColor: Colors.black,
            },
          },
        },
      },
    ],
    styleOverrides: {

      root: {
        flexShrink: 0,
        marginTop: '1px', // Чтобы рамка толщиной 2 не обрезалась, когда поле прижато к верху
        label: {
          color: 'white !important',
        },
        '& .MuiInputLabel-root': {
          transform: 'none',
          position: 'static',
          fontSize: typography.caption.fontSize,
          marginBottom: '4px',
        },
        '& p.Mui-error': {
          color: `${Colors.red6} !important`,
          marginLeft: 0,
        },
        '& label.Mui-error': {
          color: Colors.gray7,
        },
        '& .MuiOutlinedInput-input': {
          padding: '8px',
        },
        '& .MuiOutlinedInput-multiline': {
          padding: 0,
        },
        '& .MuiOutlinedInput-adornedStart': {
          paddingLeft: '8px',
          '& svg': {
            marginRight: '8px',
          },
        },
        fieldset: {
          border: '1px solid ' + Colors.white + '!important',
          borderRadius: UTILS.getInPx(4),
        },
        input: {
          color: 'white',
        },
        '& .MuiOutlinedInput-inputAdornedStart': {
          paddingLeft: 0,
        },
        '& legend': {
          width: 0,
        },
      },
    },
  },
};
export default textField;