import { ThemeOptions } from '@mui/material';
import UTILS from '../../utils';
import Colors from '$colors';

const button: ThemeOptions['components'] = {
  MuiButton: {
    styleOverrides: {
      link: {
        '&:hover': {
          transition: 'all 0.1s linear',
          transform: 'scale(1)',
        },
      },
      text: {
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiButton-endIcon': {
          margin: 0,
          padding: 0,
        },
        '& .MuiButton-startIcon': {
          margin: 0,
          padding: 0,
          paddingRight: UTILS.getInPx(24),
        },
      },
      root: {
        backgroundColor: null,
        '&:hover': {
          backgroundColor: null,
          transition: 'all 0.1s linear',
          transform: 'scale(1.06)',
        },
      },
    },
    defaultProps: {
      disableTouchRipple: true,
    },
    variants: [
      {
        props: { variant: 'tinkoff' },
        style: {
          backgroundColor: '#ffdd2d',
        },
      },
      {
        props: { variant: 'google_auth' },
        style: {
          backgroundColor: Colors.white + ' !important',
          height: UTILS.getInPx(48),
        },
      },
      {
        props: { variant: 'pro_link' },
        style: {
          minHeight: UTILS.getInPx(33),
          background: UTILS.getImportant(Colors.white),
          color: UTILS.getImportant(Colors.blue4),
          borderRadius: UTILS.getInPx(8),
        },
      },
      {
        props: { variant: 'start' },
        style: {
          minHeight: UTILS.getInPx(48),
          borderRadius: UTILS.getInPx(8),
          background: Colors.gradientStartButton,
        },
      },
      {
        props: { variant: 'muted' },
        style: {
          height: UTILS.getInPx(33),
          borderRadius: UTILS.getInPx(8),
          backgroundColor: Colors.gray2 + ' !important',
        },
      },
      {
        props: { variant: 'offer' },
        style: {
          height: UTILS.getInPx(33),
          borderRadius: UTILS.getInPx(24),
          backgroundColor: Colors.blue6 + ' !important',
          color: UTILS.getImportant(Colors.white),
          height: UTILS.getInPx(45),

        },
      },
      {
        props: { variant: 'pro' },
        style: {
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          minHeight: UTILS.getInPx(52),
          background: UTILS.getImportant(Colors.white),
          color: UTILS.getImportant(Colors.blue4),
          borderRadius: UTILS.getInPx(16),
          minWidth: UTILS.getInPx(300),
        },
      },
      {
        props: { variant: 'pro_secondary' },
        style: {
          minHeight: UTILS.getInPx(52),
          color: UTILS.getImportant(Colors.white),
          background: Colors.gradientInfoCardPro,
          borderRadius: UTILS.getInPx(16),
          minWidth: UTILS.getInPx(300),
        },
      },
      {
        props: { variant: 'offers' },
        style: {
          minHeight: UTILS.getInPx(48),
          color: UTILS.getImportant(Colors.white),
          backgroundColor: Colors.blue6,
          borderRadius: UTILS.getInPx(16),
          minWidth: UTILS.getInPx(300),
          '&:hover': {
            backgroundColor: Colors.blue6,
            transition: 'all 0.1s linear',
            transform: 'scale(1.06)',
          },
        },
      },
    ],
  },
};

export default button;