import React, { useEffect } from 'react';
import { Provider } from 'mobx-react';
import { SnackbarProvider } from 'notistack';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { I18nextProvider } from 'react-i18next';
import { IServices } from '$types';
import { useRootStore } from '$hooks/useRootStore';
import Router from './Router';
import { Api, HttpClient } from './api/api';
import Loader from '$components/system/Loader';
import { useRootService } from '$hooks/useRootService';

import './polyfills/array';
import i18n from './locales/i18n.ts';
import UTILS from './utils';
import growthbook from './utils/GrowthBookSdk';
import { CbApi } from './api/cbApi';

function App() {
  const endpoint = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_ENDPOINT_DEVELOP : process.env.REACT_APP_ENDPOINT_PRODUCTION;
  const stores = useRootStore();
  const services = useRootService();

  const httpClient = new HttpClient<any>({
    securityWorker: securityData => securityData,
    baseUrl: endpoint,
    baseApiParams: {
      secure: true,
    },
    customFetch: async (...fetchParams) => {
      if (fetchParams[1].headers.background) {
        return fetch(...fetchParams);
      }
      stores.AppStore.startLoader();
      const res = await fetch(...fetchParams);
      stores.AppStore.setLoader(false);
      return res;
    },
  });

  httpClient.setSecurityData({
    headers: {
      localization: UTILS.getLocale()
    }
  });

  const apiService = new Api(httpClient);

  const cbHttpClient = new HttpClient<any>({
    securityWorker: securityData => securityData,
    baseUrl: 'https://www.cbr-xml-daily.ru/',
    baseApiParams: {
      secure: true,
    },
    customFetch: async (...fetchParams) => {
      if (fetchParams[1].headers.background) {
        return fetch(...fetchParams);
      }
      stores.AppStore.startLoader();
      const res = await fetch(...fetchParams);
      stores.AppStore.setLoader(false);
      return res;
    },
  });
  const cbApi = new CbApi(cbHttpClient);

  services.AppService.initService(stores.AppStore);
  services.ArticlesService.initService(stores.ArticlesStore, apiService);
  services.Web3Service.initService(apiService, stores.AppStore);
  services.AuthService.initService(apiService, stores.UserStore, services.AppService);
  services.UsersService.initService(stores.UserStore, apiService);
  services.ContentService.initService(apiService);
  services.PaymentService.initService(apiService, apiService, stores.UserStore, services.AuthService, stores.AppStore);
  services.CryptoPaymentService.initService(cbApi, apiService);
  services.AaveCalculatorService.initService(apiService);

  const servicesInject = {
    appService: services.AppService,
    articlesService: services.ArticlesService,
    authService: services.AuthService,
    paymentService: services.PaymentService,
    contentService: services.ContentService,
    web3Service: services.Web3Service,
    usersService: services.UsersService,
  } as IServices;
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures({ autoRefresh: true });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        style={{
          minWidth: 400,
        }}
      >
        <I18nextProvider i18n={i18n}>
          <Provider {...stores} services={servicesInject}>
            <Loader />
            <Router />
          </Provider>
        </I18nextProvider>
      </SnackbarProvider>
    </GrowthBookProvider>

  );
}

export default App;
