import {
  action, computed, makeObservable, observable, toJS,
} from 'mobx';
import BaseNotification from '../model/Notifications/BaseNotification';
import Colors from '$colors';

export default class AppStore {
  static MAX_SNACK = 3;

  static SNACK_SHIFT_TIME = 1000;

  @observable
  headerColor: typeof Colors[keyof Colors];

  @observable
  notifications = [];

  @observable
  isLoader = false;

  @action
  setNotifications(notifications: BaseNotification[]) {
    this.notifications = notifications;
  }

  @action
  addNotification(notify) {
    this.setNotifications([...this.notifications, notify]);
  }

  @computed
  get currentNotification() {
    return this.notifications[0];
  }

  @observable
  openMenu = JSON.parse(localStorage.as_menu || 'false');

  isTimer = false;

  @action
  setLoader(value, fromTimer = false) {
    if (fromTimer) {
      if (this.isTimer) this.isLoader = value;
      this.isTimer = false;
    } else {
      this.isLoader = value;
      this.isTimer = false;
    }
  }

  getLoader = () => toJS(this.isLoader);

  startLoader() {
    this.isTimer = true;
    setTimeout(() => {
      this.setLoader(true, true);
    }, 500);
  }

  setHeaderColor(v) {
    this.headerColor = v;
  }

  @observable queue: string[] = [];

  @action
  setQueue(list) {
    this.queue = list;
  }

  constructor() {
    makeObservable(this);
  }
}
