import React from "react";
import { MOBXDefaultProps } from "$types";
import Header from "$components/system/Header/Header";
import "./styles.scss";
import Footer from "$components/system/Footer";

interface PageProps extends MOBXDefaultProps {
  children: React.ReactNode;
  hideHeader: boolean;
  hideFooter: boolean;
}

function Page(props: PageProps) {
  console.log("Page -> props", props);
  return (
    <div className="page">
      {!props.hideHeader && <Header />}
      <div className="page__content">{props.children}</div>
      {!props.hideFooter && <Footer />}
    </div>
  );
}

export default Page;
