import Colors from '$colors';
import { ThemeOptions } from '@mui/material';

const inputAdornment: ThemeOptions['components'] = {
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        zIndex: 10,
      },
    },
  },
};

export default inputAdornment;