import { ThemeOptions } from '@mui/material';
import UTILS from '../../utils';

const dialog: ThemeOptions['components'] = {
  MuiDialog: {
    styleOverrides: {
      root: {
        ".MuiBackdrop-root":{
          backgroundColor: "rgba(0, 0, 0, 0.9) !important",
        },
      },
      paper: {
        overflowX: "hidden",
        borderRadius: UTILS.getInPx(24),
        margin: UTILS.getInPx(16),
        background: 'transparent',
      },
    },
  },
};
export default dialog;
