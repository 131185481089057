import AppStore from './App.store';
import ArticlesStore from './Articles.store';
import UserStore from './User.store';

export class RootStore {
  AppStore: AppStore;

  ArticlesStore: ArticlesStore;

  UserStore: UserStore;

  constructor() {
    this.AppStore = new AppStore();
    this.ArticlesStore = new ArticlesStore();

    this.UserStore = new UserStore();
  }
}

export default new RootStore();
