import {Api} from '../api/api';

export interface AssetType {
  tokenAddress: string;
  symbol: string;
  ltv: number;
  liquidationThreshold: number;
  priceInUsd: number | null;
}

export interface CurrentConditionDto {
  assetsToBorrow: AssetType[];
  assetsToSupply: AssetType[];
}

export default class AaveCalculatorService {
  private apiService: Api<any>;

  initService(api: Api<any>) {
    this.apiService = api;
  }

  async getCurrentConditions(chain: string): Promise<CurrentConditionDto> {
    const response = await this.apiService.api.calculatorControllerGetCurrentConditions({ network: chain });
    return response.data;
  }

  async getAvailableNetworks() {
    const { data } = await this.apiService.api.calculatorControllerAvailableNetworks();

    return data;
  }
}
