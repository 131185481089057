import React, { ReactNode, useState } from 'react';
import {
  Dialog, DialogContent, Button, CardHeader, DialogActions, Typography, Paper, RadioGroup, FormControlLabel, Radio,
} from '@mui/material';
import BaseNotification from './BaseNotification';
import BaseNotificationWindow from './BaseNotificationWindow';
import logo from '$images/logo.png';

interface IConfirmConfig {
  title: string;
  body: ReactNode;
  actions: ReactNode;
}

export default class EmptyWindow extends BaseNotification implements BaseNotificationWindow {
  private title: string;

  private body: ReactNode;

  private actions: ReactNode;

  private onSubmit: () => void;

  private onClose: (() => void) | undefined;

  open(config: IConfirmConfig) {
    this.title = config.title;
    this.body = config.body;
    this.actions = config.actions;
    this.sendNotify();
  }

  getMessage(): React.ReactNode {
    return <p>{this.message}</p>;
  }

  getIcon(): React.ReactNode {
  }
  
  public close() {
    super.close();
  }
  
  getNotificationWindow(): React.ReactNode {
    return (
      <Dialog open minWidth={376} onClose={() => { this.close(); }} maxWidth="lg" aria-labelledby="confirmDialog">
        <Paper>
          <div className="p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center gap-4">
            {this.title && <Typography variant="subtitle1" textAlign="center" color="black">{this.title}</Typography>}
            <DialogContent>
              {this.body}
            </DialogContent>
            <DialogActions className="justify-content-center gap-4">
              {this.actions}
            </DialogActions>
          </div>
        </Paper>
      </Dialog>
    );
  }
}
