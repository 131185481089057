import i18n from 'i18next';
import NotificationManager from '../helpers/NotificationManager';
import { SnackType } from '../model/Notifications/PageNotification';
import { CbApi } from '../api/cbApi';
import { Api, PaymentStatusRequestDto } from '../api/api';
import { CreatePaymentsCryptoDto } from '$components/cards/OfferCard/PromoCodes.dialog';

export default class CryptoPaymentService {
  public RECEIVER = process.env.REACT_APP_CRYPTO_PAYMENT_RECIEVER;

  private cbApiService: CbApi<any>;

  private apiService: Api<any>;

  private t: (key: string) => string;

  initService(cbApi: CbApi<any>, api: Api<any>) {
    this.cbApiService = cbApi;
    this.apiService = api;
    this.t = i18n.t;
  }

  async convertToUsd(amount: number) {
    try {
      return this.cbApiService.api.convertToUsd(amount);
    } catch (e) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: e.message,
      });
    }
  }

  async create(dto: CreatePaymentsCryptoDto) {
    try {
      await this.apiService.api.paymentsCryptoControllerCreate(dto);
    } catch (e) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: `Напишите в поддержку. Ошибка: ${e.message}`,
      });
    }
  }

  async getStatus(dto: PaymentStatusRequestDto): Promise<{ status: string }> {
    try {
      const res = await this.apiService.api.paymentsCryptoControllerCheckStatus(dto);

      console.log(res);
      return res.data;
    } catch (e) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: `Напишите в поддержку. Ошибка: ${e.message}`,
      });
    }
  }
}
