import React from 'react';
import { Redirect } from 'react-router';
import MobXRouterDecorator from '$components/HOC/MobXRouterDecorator';
import { RouterEnum } from '$types';
import WindowFactory, {WindowType} from "$components/HOC/WindowFactory";

class ErrorBoundary extends React.Component<{ children: React.ReactElement, throwError?: (...params: any) => void }, { error: any, hasError: boolean }> {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      hasError: false
    };
  }

  /*
  * State updates for next render would up safe ui
  */

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <WindowFactory type={WindowType.Error}/>;
    }

    return this.props.children;
  }
}
export default MobXRouterDecorator(ErrorBoundary);
