import {
  BrowserProvider, Contract, ethers
} from 'ethers';

import i18n from 'i18next';
import ContractABI from '$assets/contracts/contract-abi.json';
import { Api } from '../api/api';
import NotificationManager from '../helpers/NotificationManager';
import AppStore from '../stores/App.store';

export default class Web3Service {
  // env
  contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;

  contract: ethers.Contract = null;

  private appStore: AppStore;

  t: (key: string) => string;

  apiService: Api<any>;

  initService(apiService: Api<any>, appStore) {
    this.apiService = apiService;
    this.appStore = appStore;
    this.t = i18n.t;
  }

  async onConnected(provider: BrowserProvider) {
    try {
      const { data } = await this.apiService.api.nftControllerCheckMintNft();
      if (data.result) {
        await this.newMint(provider);
      }
    } catch (e) {
      NotificationManager.Error.open({
        message: e?.error?.message,
      });
    }
  }

  async mint(accounts) {
    try {
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      const contract = new Contract(
        this.contractAddress,
        ContractABI,
        signer,
      );

      this.contract = contract;
      const gasLimit = await contract.estimateGas.mint(signer.address).catch((e) => {
        NotificationManager.Error.open({
          label: this.t('profile.please_contact_tech_support'),
          message: e?.message
        });
      });
      this.appStore.startLoader();
      console.log('limit', gasLimit);
      const transaction = await contract.mint();
      const request = await transaction.wait();
      console.log(request.transactionHash);

      const response = await this.apiService.api.nftControllerMint({
        tx_hash: request.transactionHash
      });
      this.appStore.setLoader(false);

      NotificationManager.Alert.open({
        message: `${this.t('services.web3service.nft_hash')} ${response.data.tx_hash}`
      });
    } catch (e) {
      this.appStore.setLoader(false);

      let errorMessages = e?.message;
      if (errorMessages.includes('ACTION_REJECT')) {
        errorMessages = this.t('services.payments.action_cancelled_by_user');
      } else if (errorMessages.includes('Caller is not in the whitelist')) {
        errorMessages = this.t('services.payments.not_in_white_list');
      }

      setTimeout(() => {
        NotificationManager.Error.open({
          label: this.t('profile.please_contact_tech_support'),
          message: errorMessages,
        });
      }, 1000);
    }
  }

  async newMint(provider: BrowserProvider) {
    try {
      const signer = await provider.getSigner();

      const contract = new Contract(
        this.contractAddress,
        ContractABI,
        signer,
      );

      this.contract = contract;
      this.appStore.startLoader();
      const transaction = await contract.mint(signer.address);
      const request = await transaction.wait();
      console.log(request);

      const response = await this.apiService.api.nftControllerMint({
        tx_hash: request.hash
      });
      this.appStore.setLoader(false);

      NotificationManager.Alert.open({
        message: `${this.t('services.web3service.nft_hash')} ${response.data.tx_hash}`
      });
    } catch (e) {
      console.log(e);
      this.appStore.setLoader(false);

      let errorMessages = e?.message;
      if (errorMessages.includes('ACTION_REJECT')) {
        errorMessages = this.t('services.payments.action_cancelled_by_user');
      } else if (errorMessages.includes('Caller is not in the whitelist')) {
        errorMessages = this.t('services.payments.not_in_white_list');
      }

      setTimeout(() => {
        NotificationManager.Error.open({
          label: this.t('profile.please_contact_tech_support'),
          message: errorMessages,
        });
      }, 1000);
    }
  }
}
