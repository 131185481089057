import React from 'react';
import {
  Button, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography,
} from '@mui/material';
import BaseNotification from './BaseNotification';
import BaseNotificationWindow from './BaseNotificationWindow';
import CommonCard from '$components/cards/CommonCard';
import logo from '$images/logo.png';
import i18next from "i18next";

interface IConfirmConfig {
  title?: string;
  buttonCancelText?: string;
  message: string;
  onClose?: () => void;
}

class AlertWindow extends BaseNotification implements BaseNotificationWindow {
  private title: string;

  private message: string;

  private buttonCancelText?: string;

  private onClose: (() => void) | undefined;

  open(config: IConfirmConfig) {
    this.title = config.title;
    this.message = config.message;
    this.buttonCancelText = config.buttonCancelText;
    this.onClose = config.onClose;

    this.sendNotify();
  }

  getMessage(): React.ReactNode {
    return <p>{this.message}</p>;
  }

  getNotificationWindow(): React.ReactNode {
    return (
      <Dialog
        open
        onClose={() => {
          this.close();
        }}
        minWidth={376}
        maxWidth="lg"
      >
        <Paper variant='dark'>
          <div className={"p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center gap-4"}>
              <img src={logo} width={132} />
            <DialogContent className="dialogContent">
              <Typography variant="subtitle1" textAlign={"center"} color="white">{this.message}</Typography>
            </DialogContent>
            <DialogActions className="justify-content-center">
              <Button
                variant={"pro_secondary"}
                onClick={() => {
                  this.close();
                  this.onClose && this.onClose();
                }}
              >
                {this.buttonCancelText || i18next.t("common.close")}
              </Button>
            </DialogActions>
          </div>
        </Paper>
      </Dialog>
    );
  }

  getIcon(): React.ReactNode {
    return null;
  }
}

export default AlertWindow;
