import { ThemeOptions } from '@mui/material';
import UTILS from '../../utils';
import Colors from '$colors';

const iconButton: ThemeOptions['components'] = {
  MuiIconButton: {
    variants: [{
      props: { color: 'blue4' },
      style: {
        padding: UTILS.getInPx(14),
        background: Colors.blue4 + ' !important',
        borderRadius: UTILS.getInPx(16),
      },
    }],
  },
};
export default iconButton;