import history from './history';
import objectToParams from './objectToParams';

class URLManager {
  setQueryParams(key, value): void {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    url.search = objectToParams(Object.fromEntries(url.searchParams.entries()));
    if (url.toString() !== window.location.href) {
      history.push({
        search: url.search
      });
    }
  }

  deleteQueryParams(key): void {
    const url = new URL(window.location.href);
    url.searchParams.delete(key);
    url.search = objectToParams(Object.fromEntries(url.searchParams.entries()));
    if (url.toString() !== window.location.href) {
      history.push({
        search: url.search
      });
    }
  }

  getQueryParams(key) : string {
    const url = new URL(window.location.href);
    return url.searchParams.get(key);
  }
}

export default new URLManager();
