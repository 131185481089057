import i18n from 'i18next';
import AppService from '$services/App.service';
import { Api, User } from '../api/api';
import { SnackType } from '../model/Notifications/PageNotification';
import UserStore from '../stores/User.store';
import NotificationManager from '../helpers/NotificationManager';
import UTILS from '../utils';

export default class AuthService {
  apiService: Api<any>;

  private userStore: UserStore;

  private appService: AppService;

  t: (key: string) => string;

  static localStorageTokenKey = 'web3academy';

  static localStorageReferralTokenKey = 'web3academy_referral';

  initService(apiService:Api<any>, userStore: UserStore, appService: AppService) {
    this.apiService = apiService;
    this.userStore = userStore;
    this.appService = appService;
    this.t = i18n.t;
  }

  constructor() {
    // this.apiService = apiService;
    // this.userStore = userStore;
    // this.appService = appService;
    const token = UTILS.URLManager.getQueryParams('invite');
    if (token) {
      // metrika push
      localStorage.setItem(AuthService.localStorageReferralTokenKey, token);
    }
  }

  async signin(email: string, password: string) {
    try {
      const a = await this.apiService.api.authControllerLogin({
        email,
        password,
      });
      const { token } = a.data;
      this.setToken(token);
      await this.authentication();
      return true;
    } catch (err) {
      this.appService.NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: `${this.t('services.auth_service.auth_error')} ${err.error.message}`,
      });
      return false;
    }
  }

  async resetPassword(email) {
    try {
      await this.apiService.api.authControllerSendPassword({ email });
      this.appService.NotificationManager.Snack.open({
        snacktype: SnackType.Success,
        message: this.t('window_factory.mailIsDelivered'),
      });
    } catch (e) {
      this.appService.NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: `${this.t('services.auth_service.auth_error')} ${e.error.message}`,
      });
    }
  }

  setToken(token) {
    if (token) {
      localStorage.setItem(AuthService.localStorageTokenKey, token);
    } else {
      localStorage.removeItem(AuthService.localStorageTokenKey);
    }
    this.apiService.http.setSecurityData({
      headers: {
        Authorization: `Bearer ${token}`,
        localization: UTILS.getLocale()
      },
    });
  }

  async changeEmail(oldEmail: string, newEmail: string) {
    try {
      await this.apiService.api.usersControllerChangeEmail({
        newEmail,
        oldEmail,
      });

      this.appService.NotificationManager.Snack.open({
        snacktype: SnackType.Success,
        message: this.t('services.auth_service.mailIsChanged'),
      });
    } catch (e) {
      this.appService.NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: `${this.t('services.auth_service.auth_error')}  ${e.error.message}`,
      });
    }
  }

  async changePassword(password, token?: string) {
    try {
      if (token) {
        this.setToken(token);
      }
      await this.apiService.api.usersControllerChangePassword({ password });

      if (token) {
        this.setToken(null);
      }

      this.appService.NotificationManager.Snack.open({
        snacktype: SnackType.Success,
        message: this.t('services.auth_service.passwordIsChanged'),
      });
    } catch (e) {
      this.appService.NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: `${this.t('services.auth_service.auth_error')}  ${e.error.message}`,
      });
    }
  }

  async signup(email: string, password: string) {
    try {
      const referrer_id = +localStorage.getItem(AuthService.localStorageReferralTokenKey);
      const { data } = await this.apiService.api.authControllerRegistration({
        email,
        password,
        referrer_id: referrer_id || null,
      });
      NotificationManager.Alert.open({
        message: this.t('window_factory.mailIsDelivered'),
        buttonCancelText: 'Закрыть',
      });
    } catch (err) {
      this.appService.NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: `${this.t('services.auth_service.auth_error')} ${err.error.message}`,
      });
    }
  }

  async authentication() {
    try {
      const token = localStorage.getItem(AuthService.localStorageTokenKey);
      if (token) {
        this.setToken(token);
        await this.updateUserData();
      } else {
        this.setToken(null);
      }
      return Promise.resolve(this.userStore.user);
    } catch (err) {
      this.userStore.setUser({});
      await this.logout();
    }
  }

  async updateUserData() {
    const { data } = await this.apiService.api.authControllerGetUserByToken();
    this.userStore.setUser(data);
  }

  async updateUser(userData: User) {
    try {
      const { response } = await this.apiService.api.usersControllerUpdateUser({
        id: this.userStore.user.id,
        ...userData,
      });
      NotificationManager.Snack.open({
        snacktype: SnackType.Success,
        message: this.t('common.good'),
      });
      await this.updateUserData();
    } catch (err) {
      this.userStore.setUser({});
    }
  }

  async logout() {
    this.userStore.setUser({});
    this.setToken(null);
    window.location.reload();
  }
}
