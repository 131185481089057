import { ThemeOptions } from '@mui/material';

const collapse:ThemeOptions['components'] = {
  MuiCollapse: {
    styleOverrides: {
      root: {
        backgroundColor: 'none !important',
      },
    },
  },
}
export default collapse