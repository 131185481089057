enum CacheEnum {
  StartStudy='StartStudy',
  getW3AValues='getW3AValues',
  getReviews='getReviews',
  getNFTBlock='getNFTBlock',
  getCategories='getCategories',
  Article='Article',
  Category='Category',
  Image='ImageUT',
  WhyAcademyPro='WhyAcademyPro',
  PopularAnswer='PopularAnswer',
  Offers='Offers',
  MINI_COURSE_OFFERS='MINI_COURSE_OFFERS',
  Rates='Rates',
  getFAQ='getFAQ',
  GetCurrentConditions='GetCurrentConditions',
}

export default CacheEnum;
