import { Locales } from '../stores/App.store';

export default class ArticleModel {
  id: number;

  alias: string;

  locale: Locales;

  data: string;

  constructor(obj: ArticleModel) {
    this.id = obj.id;
    this.alias = obj.alias;
    this.locale = obj.locale;
    this.data = obj.data;
  }
}
