import { ThemeOptions } from '@mui/material';
import UTILS from '../../utils';
import Colors from '$colors';

const accordion: ThemeOptions['components'] = {
  MuiAccordionSummary: {
    styleOverrides: {
      expandIconWrapper: {
        marginLeft: '16px',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&:before': {
          backgroundColor: 'transparent',
        },
      },
    },
    variants: [
      {
        props: { variant: 'transparent' },
        style: {
          backgroundColor: 'transparent',
          color: Colors.white,
          '.MuiAccordionSummary-root': {
            borderBottom: '1px solid #fff',
          },
        },
      },
      {
        props: { variant: 'pro' },
        style: {
          backgroundColor: 'transparent',
          color: Colors.white,
          '.Mui-Collapse-root': {
            marginTop: UTILS.getInPx(-1),
          },
          '.MuiAccordionSummary-root': {
            borderBottom: '1px solid ' + Colors.gray3,
          },
          '&:has(.Mui-expanded)': {
            background: Colors.gradientInfoCardPro,
            borderRadius: UTILS.getInPx(16),
            '.MuiAccordionSummary-root': {
              border: 0,
            },
            '*': {
              color: Colors.white + ' !important',
            },
          },
        },
      },
    ],
  },
};
export default accordion;
