import { useLocation, withRouter } from 'react-router';
import { Component, useEffect } from 'react';
import UTILS from '../../utils';

function ScrollToTop(props) {
  const location = useLocation();
  useEffect(() => {
    document.body.scroll(0, 0);
    setTimeout(() => {
      if (location.hash === '#Offer') {
        UTILS.toOffer();
      }
    }, 500);
  }, [location]);

  return <>{props.children}</>;
}

export default ScrollToTop;
