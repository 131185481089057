import {
  CategoryResponseDto,
  ContentType,
  CreateRoleDto,
  HttpClient,
  RequestParams,
} from './api';

export class CbApi<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  lastUsdToRubCourse = 0.0135383;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  api = {
    convertToUsd: async (amountInRub: number) => {
      const response = await this.http.request({
        path: 'latest.js',
        method: 'GET',
        format: 'json',
      });

      this.lastUsdToRubCourse = response.data.rates.USD;

      return amountInRub * this.lastUsdToRubCourse;
    },
  };
}
