import { ThemeOptions } from '@mui/material';
import UTILS from '../../utils';
import Colors from '$colors';

const Switch: ThemeOptions['components'] = {
  MuiSwitch: {
    variants: [
      {
        props: { variant: 'pro' },
        style: {
          height: UTILS.getInPx(48),
          width: UTILS.getInPx(240),
          background: Colors.gray1,
          borderRadius: UTILS.getInPx(100),
          padding: 0,
          '.MuiSwitch-track': {
            background: Colors.gray4 + ' !important',
            '&:before, &:after': {
              content: '""',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              width: 100,
              height: 100,
            },
            '&:before': {
              content: '"Pro"',
              left: 24,
              top: UTILS.getInPx(55),
              fontSize: UTILS.getInPx(23),
              color: Colors.white,
              fontFamily: 'Ubuntu',
            },
            '&:after': {
              content: '"Free"',
              right: -24,
              top: UTILS.getInPx(55),
              fontSize: UTILS.getInPx(23),
              color: Colors.white,
              fontFamily: 'Ubuntu',
            },
          },
          '.MuiSwitch-thumb': {
            width: 123,
            height: 44,
            boxShadow: 'none',
            borderRadius: UTILS.getInPx(100),
            background: Colors.gradientInfoCardPro,
          },
          '.MuiSwitch-switchBase': {
            padding: 0,
          },
          '.Mui-checked': {
            transform: 'translateX(120px) !important',
            color: `${Colors.white} !important`,
          },
        },
      },
    ],
  },
};
export default Switch;