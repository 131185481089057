import { useHistory, useRouteMatch } from 'react-router';
import { RouterEnum } from '$types';
import { useRootStore } from '$hooks/useRootStore';

const useRedirectAuth = (to?: RouterEnum | string) => {
  const store = useRootStore();
  if (store.UserStore.user.isActivated) {
    const history = useHistory();
    history.push(to || RouterEnum.HOME);
  }
};

export default useRedirectAuth;
