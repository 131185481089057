const obj = (value: { [key: string]: string }) => {
  let decodedString = Object.entries(value)
    .map((i) => [i[0], encodeURIComponent(i[1])].join('='))
    .join('&');
  decodedString = decodedString.replace(/%2F/gi, '/');

  return decodedString;
};

export default obj;
