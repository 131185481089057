import {
  action, makeAutoObservable, observable,
} from 'mobx';
import ArticleModel from '../model/Article.model';

export enum FeedbackStatusEnum {
  LIKE='LIKE',
  DISLIKE='DISLIKE',
}

export default class ArticlesStore {
  @observable
  currentArticles:ArticleModel | null = null;

  @observable articlesList = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setCurrentArticle(article: ArticleModel) {
    this.currentArticles = article;
  }

  @action
  setArticlesList(list) {
    this.articlesList = list.map(el => new ArticleModel(el));
  }
}
