import Colors from '$colors';
import { ThemeOptions } from '@mui/material';
import UTILS from '../../utils';

const menu: ThemeOptions['components'] = {
  MuiMenu: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
      },
      paper: {
        minWidth: '230px !important',
      },
      list: {
        paddingLeft: UTILS.getInPx(8),
        paddingRight: UTILS.getInPx(8),
      },
    },
  },
};
export default menu;