import ArticlesStore from '../stores/Articles.store';
import {
  Api, CategoryResponseDto, LibraryResponseDto,
} from '../api/api';
import NotificationManager from '../helpers/NotificationManager';
import { SnackType } from '../model/Notifications/PageNotification';
import UTILS from "../utils";
import i18n from "i18next"


export default class ArticlesService {
  articlesStore: ArticlesStore;

  apiService: Api<any>;
  t: (key: string) => string;
  

  initService(articlesStore: ArticlesStore, apiService:Api<any>){
    this.apiService = apiService;
    this.articlesStore = articlesStore;
    this.t = i18n.t
  }

  getArticlePrefix(name) {
    return UTILS.RequestCacheEnum.Article + name;
  }

  async getArticlesByAlias(alias: string) {
    try {
      const response = await this.apiService.api.articlesControllerFindOne(alias);
      return response.data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t("services.article_service.get_error_article"),
      });
    }
  }

  async getCategory(alias): Promise<CategoryResponseDto> {
    try {
      const response = await this.apiService.api.categoryControllerFindOne(alias);
      return response.data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t("services.article_service.get_error_category"),
      });
    }
  }

  async getCategories(): Promise<{ free: CategoryResponseDto[], pro: CategoryResponseDto[] }> {
    try {
      const response = await this.apiService.api.categoryControllerFindAll();
      const free = response.data.filter(v => !v.isPro);
      const pro = response.data.filter(v => v.isPro);
      return {
        free,
        pro,
      };
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t("services.article_service.get_error_category"),
      });
    }
  }
  
  async setArticleAsViewed(alias: string){
    try {
      const {data} = await this.apiService.api.articlesControllerSetOrChangeStatus(alias);
      if (data.result){
        NotificationManager.Snack.open({
          snacktype: SnackType.Success,
          message: this.t("services.article_service.status_is_viewed"),
        });
      } else {
        NotificationManager.Snack.open({
          snacktype: SnackType.Success,
          message: this.t("services.article_service.status_is_not_viewed"),
        });
      }
      return data.result
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t("services.article_service.status_is_empty"),
      });
    }
  }
}
