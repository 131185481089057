import AuthService from '$services/Auth.service';
import AppService from '$services/App.service';
import ArticlesService from '$services/Articles.service';
import ContentService from '$services/Content.service';
import PaymentService from '$services/Payment.service';
import UsersService from '$services/User.service';
import Web3Service from '$services/Web3.service';
import CryptoPaymentService from '$services/CryptoPayment.service';
import AaveCalculatorService from './AaveCalculator.service';

export class RootService {
  AppService: AppService;

  ArticlesService: ArticlesService;

  AuthService: AuthService;

  ContentService: ContentService;

  PaymentService: PaymentService;

  UsersService: UsersService;

  Web3Service: Web3Service;

  CryptoPaymentService: CryptoPaymentService;

  AaveCalculatorService: AaveCalculatorService;

  constructor() {
    this.AppService = new AppService();
    this.ArticlesService = new ArticlesService();
    this.AuthService = new AuthService();
    this.ContentService = new ContentService();
    this.PaymentService = new PaymentService();
    this.UsersService = new UsersService();
    this.Web3Service = new Web3Service();
    this.CryptoPaymentService = new CryptoPaymentService();
    this.AaveCalculatorService = new AaveCalculatorService();
  }
}

export default new RootService();
