import { v4 as uuidv4 } from 'uuid';
import { activateSubscriptionInWallet } from 'smartypay-client-subscriptions-react';
import tinkoff from '@tcb-web/create-credit';
import i18n from 'i18next';
import { Api } from '../api/api';
import UserStore from '../stores/User.store';
import AuthService from '$services/Auth.service';
import NotificationManager from '../helpers/NotificationManager';
import { SnackType } from '../model/Notifications/PageNotification';
import AppStore from '../stores/App.store';
import UTILS from '../utils';

type widget = {
  pay: (type: 'auth' | 'charge', options?: any, callbacks?: any) => void
}

declare global {
  const cp: { CloudPayments: () => widget };
}

export interface PaymentHandling {
  onComplete: () => void;
  onSuccess: () => void;
  onFail: () => void;
}

export interface PaymentParams {
  price: number;
  offer_alias: string;
  description: string;
  name?: string;
  email?: string;
  phone?: string;
  handlers?: PaymentHandling;
}

export default class PaymentService {
  private widget: widget;

  private apiService: Api<any>;

  private userStore: UserStore;

  private generalConfig: {};

  private authService: AuthService;

  t: (key: string) => string;

  static localStorageUtmContentKey = 'utm_content';

  appStore: AppStore;

  initService(api: Api<any>, apiService: Api<any>, userStore: UserStore, authService: AuthService, appStore: AppStore) {
    this.apiService = api;
    this.apiService = apiService;
    this.userStore = userStore;
    this.authService = authService;
    this.appStore = appStore;
    const content = UTILS.URLManager.getQueryParams(PaymentService.localStorageUtmContentKey);
    if (content) {
      localStorage.setItem(PaymentService.localStorageUtmContentKey, content);
    }
  }

  constructor() {
    // @ts-ignore
    this.t = i18n.t;
    this.widget = new cp.CloudPayments({
      googlePaySupport: false,
      yandexPaySupport: false,
      language: localStorage.locale,
      sbpSupport: false,
      sberPaySupport: false,
    });
    // this.apiService = api;
    // this.userStore = userStore;
    const publicId = process.env.REACT_APP_CLOUD_PAYMENTS_PUBLIC_ID;
    this.generalConfig = {
      publicId, // id из личного кабинета
      description: this.t('services.payments.subscription_buy'), // назначение
      currency: 'RUB', // валюта
      requireEmail: true,
      skin: 'mini', // дизайн виджета (необязательно)
      autoClose: 2,
    };
    // this.authService = authService;
    // this.appStore = appStore;
  }

  basePay({
    price, offer_alias, description, handlers, email, phone, name
  }: PaymentParams) {
    const beforeUnloadHandler = function (e) {
      const confirmationMessage = 'Оплата еще не подтвердилась. Вы уверены, что хотите покинуть страницу?';
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };
    window.addEventListener('beforeunload', beforeUnloadHandler);

    this.widget.pay(
      'auth',
      {
        ...this.generalConfig,
        amount: price, // сумма
        description,
        sbpSupport: false,
        accountId: this.userStore.user.account_id || uuidv4(), // идентификатор плательщика (необязательно)
        // accountId: email, // идентификатор плательщика (необязательно)
        email: email ?? this.userStore.user.email, // идентификатор плательщика (необязательно)
        invoiceId: uuidv4(), // номер заказа  (необязательно)
        data: {
          phone,
          firstName: name,
          user: this.userStore.user,
          payment: {
            offer_alias,
            interval: 'Day',
            referrer_id: localStorage.getItem(AuthService.localStorageReferralTokenKey) || null,
          }
        },
      },
      {
        onSuccess: async (options) => { // success
          try {
            const utm_content = localStorage.getItem(PaymentService.localStorageUtmContentKey) || null;
            // const { data } = await this.apiService.api.paymentControllerCreateSubscribe({
            //   invoice_id: options.invoiceId,
            //   offer_alias,
            //   interval: 'Day',
            //   referrer_id: localStorage.getItem(AuthService.localStorageReferralTokenKey) || null,
            //   utm_content
            // });
            // ym(89983653,'payments_create',{utm_content})
            handlers.onSuccess();
            await this.notificationSubscribe();
          } catch (err) {
            handlers.onSuccess();
            NotificationManager.Snack.open({
              message: err.error.message,
              snacktype: SnackType.Error,
            });
          }
          window.removeEventListener('beforeunload', beforeUnloadHandler);
        },
        onFail: (reason, options) => { // fail
          // действие при неуспешной оплате
          let message = reason;
          if (message === 'User has cancelled') {
            message = this.t('services.payments.action_cancelled_by_user');
          }
          handlers.onFail();
          NotificationManager.Snack.open({
            message,
            snacktype: SnackType.Error,
          });
          window.removeEventListener('beforeunload', beforeUnloadHandler);
        },
        onComplete: async (paymentResult, options) => {
          handlers.onComplete();
        },
      },
    );
  }

  async payCP({
    price, offer_alias, description, handlers, name, email, phone
  }: PaymentParams) {
    this.basePay({
      price, offer_alias, description, handlers, name, email, phone
    });
  }

  async pay90DaysCrypto(metamask_id, plan_id) {
    try {
      await activateSubscriptionInWallet(async () => {
        const subscription = await this.apiService.api.subscriptionsCryptoControllerCreate({
          metamask_id,
          plan_id,
          period: 90,
        });

        return subscription.data;
      });
      // const options = {
      //   invoice_id: uuidv4(),
      //   period: 90,
      //   amount: 1.00,
      // };
      // await this.confirmCryptoSubscribe(metamask_id, data.contractAddress, options);
    } catch (e) {
      console.log(e);
    }
  }

  //
  // async pay180DaysCrypto(metamask_id) {
  //   await this.apiService.api.paymentControllerCreateCryptoSubscribe({
  //     metamask_id,
  //     plan_id: 'c5vCbDXBThumE',
  //   });
  // }
  //
  // async payUnlimitedDaysCrypto(metamask_id) {
  //   await this.apiService.api.paymentControllerCreateCryptoSubscribe({
  //     metamask_id,
  //     plan_id: 'unlimited',
  //   });
  // }

  async cancelSubscribe() {
    try {
      const { data } = await this.apiService.api.subscriptionsControllerDeleteSubscribe();
      if (data.result) {
        NotificationManager.Snack.open({
          snacktype: SnackType.Success,
          message: this.t('services.payments.subscribe_cancelled_successfully'),
        });
        await this.authService.updateUserData();
      }
    } catch (e) {

    }
  }

  async getPromoCodeSales(code) {
    try {
      const { data } = await this.apiService.api.promoCodesControllerVerifyPromoCode({
        code,
      });
      if (!data.name) {
        NotificationManager.Snack.open({
          snacktype: SnackType.Error,
          message: this.t('services.payments.promocodeIsIncorrect'),
        });
      } else {
        NotificationManager.Snack.open({
          snacktype: SnackType.Success,
          message: this.t('services.payments.promocodeIsCorrect'),
        });
      }

      return data?.sale || 1;
    } catch (e) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: `${this.t('common.error')} ${e.error.message}`,
      });
      return 1;
    }
  }

  async getCreditWindow(price, orderNumber, description, email, mobilePhone, offer_id, promoCode) {
    await tinkoff[process.env.REACT_APP_STAGE === 'production' ? 'create' : 'createDemo']({
      shopId: process.env.REACT_APP_TINKOFF_SHOP_ID,
      showcaseId: process.env.REACT_APP_TINKOFF_SHOW_CASE_ID,
      orderNumber,
      promoCode,
      items: [
        {
          name: description, price, quantity: 1, vendorCode: offer_id
        },
      ],
      values: {
        contact: {
          email,
          mobilePhone,
        },
      },
      sum: price,
      webhookURL: process.env.REACT_APP_TINKOFF_CREDIT_WEBHOOK,
    }, {
      view: 'newTab'
    });
  }

  async notificationSubscribe() {
    NotificationManager.Snack.open({
      snacktype: SnackType.Success,
      message: this.t('services.payments.account_generate'),
    });
    if (this.userStore.user) {
      await this.authService.authentication();
    }
  }

  async createPaymentsOut() {
    try {
      const { data } = await this.apiService.api.referralControllerCreatePaymentsOut();
      if (data.result) {
        NotificationManager.Alert.open({
          message: this.t('services.payments.ticketIncomeIsCreated'),
          buttonCancelText: this.t('common.close'),
        });
      }
    } catch (e) {

    }
  }
}
