import i18n from 'i18next';
import {
  Api, LibraryResponseDto,
} from '../api/api';
import NotificationManager from '../helpers/NotificationManager';
import { SnackType } from '../model/Notifications/PageNotification';

export default class ContentService {
  apiService: Api<any>;

  t: (key: string) => string;

  initService(apiService: Api<any>) {
    this.apiService = apiService;
    this.t = i18n.t;
  }
  // constructor(apiService: Api<any>) {
  //   this.apiService = apiService;
  // }

  async getTopTools() {
    try {
      const { data } = await this.apiService.api.pagesComponentsControllerTools();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.error_banner_tools'),
      });
    }
  }

  async getAmountOfMentorPayments(): Promise<{ amount: number }> {
    try {
      const { data } = await this.apiService.api.getAmountOfMentorPayments();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.error_banner_tools'),
      });
    }
  }

  async getCategoriesTools() {
    try {
      const { data } = await this.apiService.api.pagesComponentsControllerToolsCategories();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.error_get_tools'),
      });
    }
  }

  async getFAQ() {
    try {
      const { data } = await this.apiService.api.pagesComponentsControllerGetFaq();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.error_faq'),
      });
    }
  }

  async getW3AValues() {
    try {
      const { data } = await this.apiService.api.pagesComponentsControllerGetW3AValues();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.error_w3a_values'),
      });
    }
  }

  async getReviews() {
    try {
      const { data } = await this.apiService.api.pagesComponentsControllerGetReviews();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.reviews'),
      });
    }
  }

  async getELibrary(): Promise<LibraryResponseDto[]> {
    try {
      const { data } = await this.apiService.api.pagesComponentsControllerGetLibrary();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.elibrary'),
      });
    }
  }

  async getOffers() {
    try {
      const { data } = await this.apiService.api.pagesComponentsControllerGetOffers();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.offers'),
      });
    }
  }

  async getRates() {
    try {
      const { data } = await this.apiService.api.pagesComponentsControllerGetRates();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.rates'),
      });
    }
  }

  async getMiniCourseOffers() {
    try {
      const { data } = await this.apiService.api.pagesComponentsControllerGetMiniCourseOffers();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.rates'),
      });
    }
  }

  async getNews(next) {
    try {
      const { data } = await this.apiService.api.newsControllerGetNews({
        next,
      });
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.news'),
      });
    }
  }

  async getNFTBlock() {
    try {
      const { data } = await this.apiService.api.pagesComponentsControllerGetNftBlock();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.content_service.nft_block'),
      });
    }
  }
}
