import React from 'react';
import {
  Button,
  CardHeader,
  Dialog, Slide, Typography, DialogActions, DialogContent, Paper
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import BaseNotification from './BaseNotification';
import BaseNotificationWindow from './BaseNotificationWindow';
import logo from '$images/logo.png';
import i18next from "i18next";

interface IErrorConfig {
  message: string;
  label?: number | string;
  onClose?: () => void;
}

export default class ErrorWindow extends BaseNotification implements BaseNotificationWindow {
  protected message: string;

  protected label: number | string;

  protected onClose: (() => void) | undefined;

  open(config: IErrorConfig) {
    this.message = config.message;
    this.label = config.label;
    this.onClose = config.onClose;
    this.sendNotify();
  }

  getMessage(): React.ReactNode {
    return <Typography variant="subtitle2" className="text-center m-0">{this.message}</Typography>;
  }

  getIcon(): React.ReactNode {
    return <ErrorIcon style={{ fontSize: '40px' }} color="error" />;
  }

  closeNotification() {
    if (this.onClose) {
      this.onClose();
    }
    this.close();
  }

  getNotificationWindow(): React.ReactNode {
    return (
      <Dialog
        open
        onClose={() => {
          this.close();
        }}
        maxWidth="lg"
      >
        <Paper variant='dark'>
          <div className={"p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center gap-4"}>
            <img src={logo} width={132} />
            {this.label && <Typography variant="subtitle1" textAlign={"center"} color="white">{this.label}</Typography>}
            <DialogContent className="dialogContent">
              <Typography variant={this.label? "subtitle2" : "subtitle1"} textAlign={"center"} color="white">{this.message}</Typography>
            </DialogContent>
            <DialogActions className="justify-content-center">
              <Button
                variant={"pro_secondary"}
                onClick={() => {
                  this.close();
                  this.onClose && this.onClose();
                }}
              >
                {i18next.t("common.close")}
              </Button>
            </DialogActions>
          </div>
        </Paper>
      </Dialog>
    );
  }
}
