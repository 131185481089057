import { ThemeOptions } from '@mui/material';
import { LabComponents } from '@mui/lab/themeAugmentation';
import Colors from '$colors';

const labs: ThemeOptions['components'] | LabComponents = {
  MuiTreeItem: {
    styleOverrides: {
      root: {
        '.Mui-selected': {
          backgroundColor: `${Colors.gray3} !important`,
          borderRadius: "16px",
          color: Colors.black,
        },
      },
      content: {
        padding: '16px 0px 16px 8px',
      }
    },
  },
  MuiTreeView: {
    styleOverrides: {
      root: {
        height: '100% !important',
      }
    }
  }

};

export default labs;
