import React, { useEffect, useState } from 'react';
import './index.scss';

import { Typography } from '@mui/material';
import MobXRouterDecorator from '$components/HOC/MobXRouterDecorator';
import { MOBXDefaultProps, RouterEnum } from '$types';
import LinkLine from '$components/LinkLine';
import SvgIcons from '$common/SvgIcons';
import logo from '$images/logo.png';
import { useRootStore } from '$hooks/useRootStore';

function Header(props: MOBXDefaultProps) {
  const store = useRootStore();
  // const changeLanguage = (language: Locales) => {
  //   props.AppStore.setLocales(language);
  // };

  return (
    <header
      className="d-flex justify-content-center w-100"
      style={{
        backgroundColor: store.AppStore.headerColor,
        background: store.AppStore.headerColor,
      }}

    >
      <div className="container d-flex align-items-center justify-content-between w-100 py-4 px-2">
        <div className="cursor-pointer d-flex flex-row gap-2 align-items-center user-select-none" onClick={() => props.history.push(RouterEnum.HOME)}>
          <img src={logo} width={132} />
        </div>
        <LinkLine key={1} />
      </div>

    </header>
  );
}

export default MobXRouterDecorator(Header);
