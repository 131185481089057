import { RouteComponentProps } from "react-router";
import AppService from "$services/App.service";
import ArticlesService from "$services/Articles.service";
import AuthService from "$services/Auth.service";
import { RootStore } from "../stores/Root.store";
import PaymentService from "$services/Payment.service";
import UsersService from "$services/User.service";
import ContentService from "$services/Content.service";
import Web3Service from "$services/Web3.service";

export interface IServices {
  appService: AppService;
  contentService: ContentService;
  articlesService: ArticlesService;
  authService: AuthService;
  usersService: UsersService;
  paymentService: PaymentService;
  web3Service: Web3Service;
}

export interface MOBXDefaultProps extends Partial<RootStore>, Partial<RouteComponentProps> {}

export enum RouterEnum {
  HOME = "/",
  CATEGORY = "/category",
  TOOLS = "/tools",
  OTHER = "/e-books",
  ACADEMYPRO = "/academy_pro",
  SIGNIN = "/signin",
  NEWS = "/news",
  PROFILE = "/profile",
  REFERRER = "/referrer",
  TERMS_OF_SERVICE = "/tos",
  MINI_COURSE_OFFERS_FOR_MONDAY = "/offers_32412",
  MINI_COURSE_OFFERS_FOR_TUESDAY = "/offers_12345",
  MINI_COURSE_OFFERS_FOR_WENDNESDAY = "/offers_67890",
  MINI_COURSE_OFFERS_FOR_THURSDAY = "/offers_398",
  MINI_COURSE_OFFERS_FOR_FRIDAY = "/offers_54321",
  MINI_COURSE_OFFERS_FOR_SATURDAY = "/offers_11111",
  MINI_COURSE_OFFERS_FOR_SUNDAY = "/offers_99999",
  MINI_COURSE_RECORD_FOR_MONDAY = "/mini_course_record_32412",
  MINI_COURSE_RECORD_FOR_TUESDAY = "/mini_course_record_12345",
  MINI_COURSE_RECORD_FOR_WENDNESDAY = "/mini_course_record_67890",
  MINI_COURSE_RECORD_FOR_THURSDAY = "/mini_course_record_398",
  MINI_COURSE_RECORD_FOR_FRIDAY = "/mini_course_record_54321",
  MINI_COURSE_RECORD_FOR_SATURDAY = "/mini_course_record_11111",
  MINI_COURSE_RECORD_FOR_SUNDAY = "/mini_course_record_99999",
  PARTNERS = "/partners",
  CONFIRM_USER = "/auth/user/confirm/:token",
  ERROR = "/error",
  CONFIRM_PASSWORD = "/auth/password/confirm/:token",
  RESET_PASSWORD = "/reset_password",
  SIGNUP = "/signup",
  EXCHANGE = "/exchange",
  SWAP = "/swap",
  TRANSACTION_STATUS = "/transaction/:chainName/:txHash",
  SPECIAL_OFFERS = "/special_offers",
  AAVE_CALCULATOR = "/calculator",
  BOOTCAMP = "/bootcamp",
  BOOTCAMP_LIVE = "/bootcamp-live",
  BOOTCAMP_TEST = "/dpsfksdpfkspdfkspdfksdpfk",
  BOOTCAMP_9900 = "/jfnwieFHIWoefeflhkb",
  AI_ASSISTANT = "/ai_assistant",
}

export enum RolesTypes {
  ADMIN = "ADMIN",
  USER_REGISTER = "USER_REGISTER",
  USER_RATES = "USER_RATES",
  USER_PREMIUM = "USER_PREMIUM",
  USER_INCOGNITO = "USER_INCOGNITO",
}

export enum systemArticles {
  SUBSCRIBE_PRO = "subscribe_pro",
  NEEDS_REGISTER = "needs_register",
  COMING_SOON = "coming_soon",
}
// import 'i18next';
// declare module 'i18next' {
//   interface CustomTypeOptions {
//     keySeparator: "."
//     nsSeparator: "."
//     resources: typeof en;
//   }
// }
