import i18n from 'i18next';
import {
  Api,
} from '../api/api';
import UserStore from '../stores/User.store';
import NotificationManager from '../helpers/NotificationManager';
import { SnackType } from '../model/Notifications/PageNotification';
import AuthService from '$services/Auth.service';

export default class UsersService {
  usersStore: UserStore;

  apiService: Api<any>;

  t: (key: string) => string;

  initService(usersStore: UserStore, apiService: Api<any>) {
    this.apiService = apiService;
    this.usersStore = usersStore;
    this.t = i18n.t;
  }

  // constructor(apiService: Api<any>, usersStore: UserStore) {
  //   this.apiService = apiService;
  // }

  async getReferrerLink() {
    try {
      const { data } = await this.apiService.api.referralControllerGetReferrerLink();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.user_service.referral_error'),
      });
    }
  }

  async getReferrals() {
    try {
      const { data } = await this.apiService.api.referralControllerGetReferralsList();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.user_service.referral_get_error'),

      });
    }
  }

  async getRegisteredReferrals() {
    try {
      const { data } = await this.apiService.api.referralControllerGetRegisteredReferralsList();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.user_service.referral_get_error'),

      });
    }
  }

  async getPaymentsOut() {
    try {
      const { data } = await this.apiService.api.referralControllerGetPaymentsOutHistory();
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.user_service.transaction_error'),
      });
    }
  }

  async sendPersonalData(email, phone, name, debt_id, offer_alias, referrerId) {
    try {
      const body = {
        email,
        phone,
        name,
        debt_id,
        rates_alias: offer_alias,
        referrer_id: localStorage.getItem(AuthService.localStorageReferralTokenKey) || referrerId,
      };
      const { data } = await this.apiService.api.crmControllerSendPersonalDataInCrm(body, { headers: { background: true } });
      this.usersStore.setUserPersonalData(data);
      return data;
    } catch (err) {
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.user_service.amo_crm_error'),
      });
    }
  }

  async getPersonalData(email: string) {
    if (!email) return;
    try {
      const { data } = await this.apiService.api.crmControllerGetPersonalDataInCrm(email);
      this.usersStore.setUserPersonalData(data);
    } catch (err) {
      console.log(err);
      NotificationManager.Snack.open({
        snacktype: SnackType.Error,
        message: this.t('services.user_service.getting_amo_crm_error'),

      });
    }
  }
}
