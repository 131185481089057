import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import BaseNotificationWindow from './model/Notifications/BaseNotificationWindow';
import { MOBXDefaultProps } from '$types';
import MobXRouterDecorator from '$components/HOC/MobXRouterDecorator';
import AppStore from './stores/App.store';
import { useRootStore } from '$hooks/useRootStore';
import { useRootService } from '$hooks/useRootService';

function NotificationWindow(props: MOBXDefaultProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const stores = useRootStore();
  const services = useRootService();
  const store = stores.AppStore;

  const currentNotification = store.currentNotification as unknown as BaseNotificationWindow;

  if (currentNotification && currentNotification.snacktype) {
    const { queue } = store;
    const notify = currentNotification;
    if (queue.length >= AppStore.MAX_SNACK || queue.includes(notify.id)) return null;
    enqueueSnackbar(notify.message, {
      key: notify.id,
      variant: notify.snacktype,
      onExited: (a, b) => {
        const arr = store.queue.filter(el => el !== b);
        services.AppService.closeNotify(notify);
        store.setQueue(arr);
      },
      persist: notify.persist,
      action: notify.action,
      onClick: () => {
        closeSnackbar(notify.id);
      },
      onEnter: () => {
        store.setQueue([...queue, notify.id]);
        if (queue.length <= AppStore.MAX_SNACK) {
          setTimeout(() => {
            services.AppService.closeNotify(notify);
          }, AppStore.SNACK_SHIFT_TIME);
        }
      }
    });
    return null;
  }

  return <>{currentNotification && currentNotification.getNotificationWindow()}</>;
}

export default MobXRouterDecorator(NotificationWindow);
