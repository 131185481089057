import React, { useEffect, useMemo, useState } from 'react';
import MobXRouterDecorator from '$components/HOC/MobXRouterDecorator';
import { MOBXDefaultProps } from '$types';
import { useRootService } from '$hooks/useRootService';
import { useRootStore } from '$hooks/useRootStore';

const AuthProvider: React.FC<MOBXDefaultProps> = ({
  children,
  // services,
  // AppStore,
}) => {
  const services = useRootService();
  const [isReady, setIsReady] = useState(false);
  const authService = services;
  const store = useRootStore();

  useMemo(() => {
    store.AppStore.startLoader();
  }, []);

  useEffect(() => {
    services.AuthService.authentication()
      .then(() => {
        store.AppStore.setLoader(false);
        setIsReady(true);
      });
  }, []);

  return isReady ? children : null;
};

export default MobXRouterDecorator(AuthProvider, false);
