import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@mui/material';
import { RootStoreProvider } from '$hooks/useRootStore';
import yandexMetrika from '$assets/metriks/yandex.metrika';
import App from './App';
import { RootServiceProvider } from '$hooks/useRootService';
import theme from '$styles/muiTheme';

if (process.env.NODE_ENV === 'production') {
  const metrikaContainer = document.getElementById('metrika');
  metrikaContainer.innerHTML = yandexMetrika;
  (function (m, e, t, r, i, k, a) {
    m[i] = m[i] || function () {
      (m[i].a = m[i].a || []).push(arguments);
    };
    m[i].l = 1 * new Date();
    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
  }(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym'));
  ym(89983653, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  });
}
const root = createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 20 минут на кэширование
      staleTime: 1200000,
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <RootStoreProvider>
      <RootServiceProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </RootServiceProvider>
    </RootStoreProvider>
  </ThemeProvider>
);
