import { action, makeObservable, observable } from 'mobx';
import { User } from '../api/api';

export default class UserStore {
  @observable user: User | null = {};
  
  
  @observable userPersonalData: {email: string, phone: string, name: string, debt_id: string} = {};
  
  
  

  constructor() {
    makeObservable(this);
  }

  @action setUser = (obj: any) => {
    this.user = obj || {};
  };
  
  @action setUserPersonalData = (obj: any) => {
    if (!obj) return
    delete obj.id;
    this.userPersonalData = obj || {};
  };

  @action
  updateUser = (key: keyof User, value) => {
    this.user[key] = value;
  };
}
