import React from 'react';
import {
  Dialog, DialogContent, Button, CardHeader, DialogActions, Typography, Paper,
} from '@mui/material';
import BaseNotification from './BaseNotification';
import BaseNotificationWindow from './BaseNotificationWindow';
import logo from '$images/logo.png';


interface IConfirmConfig {
  title: string;
  buttonSubmitText?: string;
  buttonCancelText?: string;
  message: string;
  hideCloseButton?: boolean;
  hideConfirmButton?: boolean;
  onSubmit: () => void;
  onClose?: () => void;
}

export default class ConfirmWindow extends BaseNotification implements BaseNotificationWindow {
  private title: string;
  
  private hideCloseButton: boolean;
  
  private hideConfirmButton: boolean;

  private message: string;

  private buttonSubmitText?: string;

  private buttonCancelText?: string;

  private onSubmit: () => void;

  private onClose: (() => void) | undefined;

  open(config: IConfirmConfig) {
    this.title = config.title;
    this.hideCloseButton = config.hideCloseButton;
    this.hideConfirmButton = config.hideConfirmButton;
    this.message = config.message;
    this.buttonSubmitText = config.buttonSubmitText;
    this.buttonCancelText = config.buttonCancelText;
    this.onSubmit = config.onSubmit;
    this.onClose = config.onClose;

    this.sendNotify();
  }

  getMessage(): React.ReactNode {
    return <p>{this.message}</p>;
  }

  getIcon(): React.ReactNode {
  }

  getNotificationWindow(): React.ReactNode {
    return (
      <Dialog open minWidth={376} onClose={() => {this.close()}} maxWidth="lg" aria-labelledby="confirmDialog">
        <Paper>
          <div className="p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center gap-4">
            {this.title && <Typography variant="subtitle1" textAlign="center" color="black">{this.title}</Typography>}
            <DialogContent>
              <Typography variant="subtitle1" textAlign="center" color="black">{this.message}</Typography>
            </DialogContent>
            <DialogActions className="justify-content-center gap-4">
              <Button
                variant="offers"
                onClick={() => {
                  this.close();
                  this.onSubmit && this.onSubmit();
                }}
              >
                {this.buttonSubmitText || 'Подтвердить'}
              </Button>
              {!this.hideCloseButton && <Button
                variant="pro"
                onClick={() => {
                  this.close();
                  this.onClose && this.onClose();
                }}
              >
                {this.buttonCancelText || 'Закрыть'}
              </Button>}
            </DialogActions>
          </div>
        </Paper>
      </Dialog>
    );
  }
}
